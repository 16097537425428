import { gsap } from 'gsap';
import React, { useLayoutEffect, useRef, useState } from 'react';

interface ExitEnterWrapperProps {
  render: boolean;
  children: React.ReactNode;
  instantRender?: boolean;
  onAnimationComplete?: () => void;
  wrapperClasses?: string;
}

export default function ExitEnterWrapper(props: ExitEnterWrapperProps) {
  const { children, render, instantRender, onAnimationComplete, wrapperClasses } = props;
  const childrenRef = useRef(null);
  const [privateRender, setPrivateRender] = useState(true);

  useLayoutEffect(() => {
    const g = gsap.to(childrenRef.current, {
      opacity: render ? 1 : 0,
      duration: 0.25,
      ease: render ? 'power1.out' : 'power1.in',
      onComplete: () => {
        setPrivateRender(render);
        if (onAnimationComplete) {
          onAnimationComplete();
        }
      }
    });
    return () => {
      g.kill();
    };
  }, [render, childrenRef, onAnimationComplete]);

  return privateRender || render ? (
    <div ref={childrenRef} className={`z-40 ${instantRender ? 'opacity-1' : 'opacity-0'} ${wrapperClasses}`}>
      {children}
    </div>
  ) : null;
}

import React from 'react';
import IconLabel from '../../components/IconLabel';
import RadioOption from '../../components/RadioOption';
import textBackground from '../../assets/images/text_background_2.png';
import TextBackground from '../../components/TextBackground';
import Button from '../../components/Button';
import ContentWrapper from '../../components/ContentWrapper/ContentWrapper';
import labelBackground1 from '../../assets/images/label_background_1.png';
import ActivityCard from '../../components/ActivityCard';
import partialLogo from '../../assets/images/partial_logo.png';
import { FilterOption, FilterType } from '../../types/interfaces';

interface SelectionStepProps {
  title: string;
  type?: FilterType;
  subtitle?: string;
  isBaseCamp?: boolean;
  index: number;
  options: FilterOption[];
  onContinue: (option: FilterOption) => void;
  onBack?: () => void;
  className?: string;
}

const SelectionStep: React.FC<SelectionStepProps> = ({
  title,
  type,
  subtitle,
  isBaseCamp,
  index,
  options,
  onContinue,
  onBack,
  className = ''
}) => {
  return (
    <>
      <ContentWrapper className={`${className} pb-10 sm:!justify-center sm:mt-[-66px]`}>
        <IconLabel className="relative mb-[-22px]" background={labelBackground1}>
          <p className={`w-full text-center text-white text-[48px] mt-[-16px] font-bigshot`}>
            {isBaseCamp ? <img src={partialLogo} className="invert -mt-[18px] w-[50px]" alt="Bazni tabor" /> : index}
          </p>
        </IconLabel>
        <TextBackground
          backgroundImage={textBackground}
          className="max-w-[100vw] overflow-hidden md:overflow-visible"
          imgClassName="rotate-[5deg] sm:rotate-0 w-full min-h-[130%] sm:min-w-[130%] sm:min-h-[190%]"
        >
          <h2 className="text-black text-center p-4 sm:p-0">{title}</h2>
        </TextBackground>
        <p className="text-center mt-6">{subtitle}</p>
        {type === FilterType.big ? (
          <div className="flex justify-center flex-row gap-4 mt-4 sm:mt-10 flex-wrap">
            {options.map((option) => (
              <ActivityCard
                key={option.id}
                title={option.title}
                description={option.description!}
                backgroundImage={option.imageUrl}
                onClick={() => onContinue(option)}
                comingSoon={option.isDisabled}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-row gap-14 sm:gap-10 mt-12 sm:mt-[80px] justify-center flex-wrap  self-center mx-8">
            {options.map((option) => {
              return (
                <RadioOption
                  key={option.id}
                  onSelect={() => onContinue(option)}
                  backgroundRotation={0}
                  title={option.title}
                  description={option.description}
                  icon={option.iconUrl}
                />
              );
            })}
          </div>
        )}
      </ContentWrapper>
      {onBack && (
        <Button
          variant="back"
          className="relative self-start sm:absolute bottom-4 sm:bottom-6 left-4 mt-10 z-10"
          onClick={onBack}
        >
          nazaj
        </Button>
      )}
    </>
  );
};

export default SelectionStep;

import React from 'react';
import TextBackground from '../TextBackground';
import textBackground from '../../assets/images/text_background_5.png';
import { Link } from 'react-router-dom';

interface NavigationMenuProps {
  tabs: { name: string; url?: string; onClick?: () => void; selected?: boolean }[];
  className?: string;
  variant?: 'itemBackground' | 'underline';
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ tabs, variant = 'underline', className = '' }) => {
  return (
    <nav className={`flex flex-row items-center gap-[45px] z-10 ${className}`}>
      {tabs.map((tab) => {
        return (
          <NavigationItem
            key={tab.name}
            title={tab.name}
            url={tab.url}
            variant={variant}
            selected={tab.selected || false}
            onClick={tab.onClick}
          />
        );
      })}
    </nav>
  );
};

interface NavigationItemProps {
  title: string;
  url?: string;
  variant: 'itemBackground' | 'underline';
  selected: boolean;
  onClick?: () => void;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ title, url, variant, selected, onClick }) => {
  let extraClass = '';
  if (variant === 'underline') {
    extraClass += ' hover:underline';
  }
  if (selected) {
    extraClass += ' !text-primary-orange';
  }
  const finalClass = `text-t2 text-white decoration-4 hover:text-primary-orange ${extraClass} cursor-pointer`;
  let content = null;
  if (!url) {
    content = (
      <div onClick={onClick} className={finalClass}>
        {title}
      </div>
    );
  } else {
    content = (
      <Link className={finalClass} to={url}>
        {title}
      </Link>
    );
  }
  if (variant !== 'itemBackground' || !selected) {
    return content;
  }
  return (
    <TextBackground backgroundImage={textBackground} imgClassName="min-w-[155%] min-h-[115%]">
      {content}
    </TextBackground>
  );
};

export default NavigationMenu;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import productLeftBackground from '../../assets/images/product_left_background.png';
import Button from '../../components/Button';
import NavigationMenu from '../../components/NavigationMenu';
import { ROUTES } from '../../constants';
import useProducts from '../../hooks/useProducts';
import partialLogo from '../../assets/images/partial_logo.png';
import { getProductsImages, productHasReview } from '../../utils/helpers';
import ProductDetails from '../../components/ProductDetails/ProductDetails';
import { Product } from '../../types/interfaces';
import CircleWithIcon from '../../components/CircleWithIcon/CircleWithIcon';
import ProductTehnical from '../../components/ProductTehnical/ProductTehnical';
import ProductReview from '../../components/ProductReview/ProductReview';
import useIsMobile from '../../hooks/useIsMobile';
import IconBack from '../../assets/images/ic-back.png';
import BouncyButton from '../../components/BouncyButton/BouncyButton';
import Modal from '../Modal/Modal';
import ImageGallery from '../../components/ImageGallery/ImageGallery';

const TABS = [
  {
    short: 'izdelek',
    long: 'izdelek',
    url: 'podrobnosti'
  },
  {
    short: 'tehnično',
    long: 'tehnično',
    url: 'tehnicno'
  },
  {
    short: 'mnenje',
    long: 'mnenje specialista',
    url: 'mnenje'
  }
];

export default function ProductsPage() {
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState<undefined | number>(undefined);
  const isMobile = useIsMobile();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, products] = useProducts()!;

  useEffect(() => {
    if (!isMobile && products.length) {
      setSelectedProduct(products[0]);
    }
  }, [isMobile, products]);

  const onProductSelect = (product: Product) => {
    setSelectedProduct(product);
  };

  function tabOnBack() {
    setSelectedProduct(undefined);
    setSelectedTab(0);
  }

  function onCloseModal() {
    setSelectedImage(undefined);
  }

  const renderTab = () => {
    if (!selectedProduct) return null;

    switch (selectedTab) {
      case 0:
        return (
          <ProductDetails
            product={selectedProduct}
            onReadMore={() => setSelectedTab(1)}
            onImageClick={(index) => setSelectedImage(index)}
          />
        );
      case 1:
        return <ProductTehnical product={selectedProduct} />;
      case 2:
        return <ProductReview product={selectedProduct} />;
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={`transition ease-in-out flex flex-row mt-[-64px] sm:mt-[-80px] max-h-full w-[200vw] sm:w-auto ${
          selectedProduct ? '-translate-x-1/2 sm:translate-x-0' : 'translate-x-0'
        }`}
      >
        <Link to={ROUTES.BASE_CAMP} className="sm:hidden z-20">
          <div className="absolute top-4 left-4">
            <CircleWithIcon icon={<img src={partialLogo} className="invert w-[32px]" alt="V bazni tabor" />} />
          </div>
        </Link>
        <div className="absolute h-[100px] from-black via-[rgba(0,0,0,0.85)] to-transparent bg-gradient-to-b w-[350px] sm:w-[440px] top-0 z-10 pointer-events-none" />
        <div
          className="hide-scrollbar-desktop bg-black sm:bg-[transparent] bg-repeat-y bg-right pt-[100px] w-screen min-h-screen sm:w-[488px] sm:px-8 overflow-y-scroll h-full flex-shrink-0 bg-contain bg-y-repeat p-4 flex flex-col items-start"
          style={{
            backgroundImage: `linear-gradient(to right, rgba(0, 0, 0) 30%, rgba(0, 0, 0, .0)), url(${productLeftBackground})`
          }}
        >
          <h3 className="w-[316px] sm:max-w-[50%]">tvoj set je pripravljen!</h3>
          <div className="products-container flex flex-row flex-wrap gap-4 sm:gap-6 mt-10 justify-center sm:justify-start">
            {products.map((product) => {
              const selected = selectedProduct && product.id === selectedProduct.id;
              return (
                <BouncyButton
                  key={product.id}
                  onClick={() => onProductSelect(product)}
                  className={`relative flex flex-col h-[203px] w-[150px] border-2 ${
                    selected ? 'border-primary-orange' : 'border-white'
                  } p-2 px-4 gap-2 hover:border-primary-orange !overflow-hidden items-center`}
                >
                  <img className="max-h-9 object-contain" src={product.brandImageUrl} alt="" />
                  <img className="w-full h-full object-contain" src={product.heroImageUrl} alt="" />
                </BouncyButton>
              );
            })}
          </div>
          <Link to={ROUTES.BASE_CAMP}>
            <Button className="mt-6 mb-[300px] -ml-10" variant="basic">
              nazaj v bazni tabor
            </Button>
          </Link>
        </div>
        <div className="relative flex justify-between sm:justify-start sm:flex-col w-screen sm:w-full overflow-y-scroll sm:pl-[100px] sm:-ml-12">
          <NavigationMenu
            className="fixed sm:w-auto sm:relative mt-12 px-8 sm:px-0 w-screen sm:left-0 right-0 bottom-4 justify-between sm:justify-start"
            tabs={TABS.filter((tab) => {
              if (tab.url === 'mnenje' && selectedProduct && !productHasReview(selectedProduct!)) {
                return false;
              }
              return true;
            }).map((tab, index) => ({
              name: tab.short,
              onClick: () => setSelectedTab(index),
              selected: selectedTab === index
            }))}
            variant="itemBackground"
          />
          <div className="p-4 sm:p-0 h-full w-full">
            <button onClick={tabOnBack} className="sm:hidden fixed z-20">
              <CircleWithIcon icon={<img src={IconBack} alt="Ikona" className="w-[32px] aspect-square" />} />
            </button>
            {renderTab()}
          </div>
        </div>
      </div>
      <Modal open={selectedImage !== undefined} onClose={onCloseModal}>
        <ImageGallery
          initalIndex={selectedImage || 0}
          images={selectedProduct ? getProductsImages(selectedProduct) : []}
        />
      </Modal>
      <div className="fixed left-0 bottom-0 h-full w-full bg-[rgba(0,0,0,0.3)] -z-[1]" />
    </>
  );
}

import { Product } from '../types/interfaces';
import api from './api';

function getProducts() {
  return api.get<{ products: Product[] }>('/products/all');
}

function getFilteredProducts(params?: any) {
  return api.get<{ products: Product[] }>('/products', { params });
}

export { getProducts, getFilteredProducts };

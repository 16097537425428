import React from 'react';
import { Product } from '../../types/interfaces';

interface ProductTehnicalProps {
  product: Product;
}

const ProductTehnical: React.FC<ProductTehnicalProps> = ({ product }) => {
  return (
    <div className="relative mt-[70px] sm:max-w-[700px] pb-10">
      <h3 className="mb-8">tehnično</h3>
      <div className="product-tehnical" dangerouslySetInnerHTML={{ __html: product.technicalDescription }}></div>
    </div>
  );
};

export default ProductTehnical;

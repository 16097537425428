import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import usePrevious from '../hooks/usePrevious';
import { ROUTES } from '../constants';
import { FilterWithSelectedValue } from '../types/interfaces';
import { playAll, setMute } from '../utils/sound';

interface GlobalContextState {
  basecampState: { isAnimating: boolean; selectedFilters: FilterWithSelectedValue[] };
  setBasecampState: (value: { isAnimating: boolean; selectedFilters: FilterWithSelectedValue[] }) => void;
  step: number;
  prevPathname: string | undefined;
  setSound: (muted: boolean) => void;
  soundMuted: boolean;
}
export const GlobalStateContext = createContext<GlobalContextState>({
  basecampState: { isAnimating: false, selectedFilters: [] },
  setBasecampState: () => {},
  step: 1,
  prevPathname: undefined,
  setSound: () => {},
  soundMuted: true
});

interface GlobalStateProviderProps {
  children: React.ReactNode;
}

export default function GlobalStateContextProvider(props: GlobalStateProviderProps) {
  const { children } = props;
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  const [basecampState, setBasecampState] = useState<{
    isAnimating: boolean;
    selectedFilters: FilterWithSelectedValue[];
  }>({
    isAnimating: false,
    selectedFilters: []
  });
  const soundPlayed = useRef<boolean>(false);
  const [soundMuted, setSoundMuted] = useState<boolean>(true);

  function setSound(muted: boolean) {
    if (!soundPlayed.current && !muted) {
      soundPlayed.current = true;
      playAll();
    }
    setMute(muted);
    setSoundMuted(!soundMuted);
  }

  const step = useMemo(() => {
    return basecampState.selectedFilters.length;
  }, [basecampState.selectedFilters]);

  useEffect(() => {
    if (pathname === ROUTES.BASE_CAMP && prevPathname !== ROUTES.BASE_CAMP) {
      setBasecampState({ ...basecampState, selectedFilters: [] });
    }
  }, [pathname, prevPathname, basecampState, setBasecampState]);

  const value = { basecampState, setBasecampState, step, prevPathname, setSound, soundMuted };
  return <GlobalStateContext.Provider value={value}>{children}</GlobalStateContext.Provider>;
}

import React from 'react';

interface CircleWithIconProps {
  icon: string | React.ReactNode;
  extraClasses?: string;
}

const CircleWithIcon: React.FC<CircleWithIconProps> = ({ icon, extraClasses = '' }) => {
  return (
    // TODO: recheck if across the app we use the same border color
    // <div className="flex justify-center items-center h-12 w-12 rounded-full border-2 border-[#D9D9D9] border-solid">
    <div
      className={`flex justify-center items-center h-12 w-12 rounded-full border-2 border-solid border-[rgba(255,255,255,0.2)] ${extraClasses}`}
    >
      {typeof icon === 'string' ? <img src={icon} alt="Ikona" /> : icon}
    </div>
  );
};

export default CircleWithIcon;

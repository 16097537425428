import { gsap } from 'gsap';
import { useEffect } from 'react';
import { Object3D, Vector3 } from 'three';

export default function useLookAroundCamera(
  scene: Object3D | null,
  enabled: boolean = true,
  turnSpeed: number = 1,
  turnRadius = { x: Math.PI / 180, y: Math.PI / 180 }
) {
  useEffect(() => {
    if (!enabled || !scene) return undefined;
    const onMouseMove = (event: MouseEvent) => {
      const normalizedMousePosition: Vector3 = new Vector3(event.x / window.innerWidth, event.y / window.innerHeight, 0)
        .multiplyScalar(2)
        .subScalar(1);

      normalizedMousePosition.x *= -turnRadius.x;
      normalizedMousePosition.y *= -turnRadius.y;

      gsap.to(scene.rotation, {
        z: normalizedMousePosition.y,
        y: normalizedMousePosition.x,
        duration: turnSpeed
      });
    };
    window.addEventListener('mousemove', onMouseMove);
    return () => window.removeEventListener('mousemove', onMouseMove);
  }, [scene, enabled, turnRadius, turnSpeed]);
}

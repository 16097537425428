import React from 'react';
import labelBackground from '../../assets/images/label_background.png';

interface IconLabelProps {
  children: React.ReactNode;
  background?: string;
  backgroundRotation?: number;
  backgroundSize?: number;
  backgroundColor?: string;
  textColor?: string;
  fontSize?: number;
  fontFamily?: string;
  className?: string;
  imgClassName?: string;
}

const IconLabel: React.FC<IconLabelProps> = ({
  children,
  background = labelBackground,
  backgroundRotation = 0,
  backgroundSize = 70,
  className = '',
  imgClassName = ''
}) => {
  return (
    <div className={`icon-label inline-block font-bigshot ${className}`}>
      <div className="absolute translate-x-[-50%] left-[50%] top-[50%] translate-y-[-50%] z-10">{children}</div>
      <img
        id="label-background"
        style={{
          minWidth: backgroundSize,
          transform: `rotate(${backgroundRotation}deg)`
        }}
        className={imgClassName}
        src={background}
        alt=""
      />
    </div>
  );
};

export default IconLabel;

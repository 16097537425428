import { Product } from '../types/interfaces';

export const getProductsImages = (product: Product) => {
  const images: string[] = [];
  if (product.heroImageUrl) {
    images.push(product.heroImageUrl);
  }
  for (let i = 1; i <= 7; i++) {
    const imageUrl = product[`image${i}Url` as keyof Product] as string;
    if (imageUrl) {
      images.push(imageUrl);
    }
  }
  return images;
};

export const stripHtmlTags = (html: string) => {
  return html.replace(/(<([^>]+)>)/gi, '');
};

export const productHasReview = (product: Product) => {
  if (!product.description) return false;

  let reviewText = stripHtmlTags(product.description);

  return !!reviewText;
};

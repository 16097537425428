import React, { useEffect, useRef, useState } from 'react';
import BouncyButton from '../BouncyButton/BouncyButton';
import CircleWithIcon from '../CircleWithIcon/CircleWithIcon';
import carretLeft from '../../assets/images/carret_left.png';
import { gsap } from 'gsap';
import useSwipe from '../../hooks/useSwipe';

interface ImageGalleryProps {
  images: string[];
  initalIndex: number;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({ images, initalIndex }) => {
  const [currentImage, setCurrentImage] = useState(initalIndex);
  const imagesContainerRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLDivElement>(null);
  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      if (currentImage === images.length - 1) return;
      onMoveImages(true)();
    },
    onSwipedRight: () => {
      if (currentImage === 0) return;
      onMoveImages(false)();
    }
  });

  function onMoveImages(forward: boolean) {
    return () => setCurrentImage((prev) => (forward ? prev + 1 : prev - 1));
  }

  useEffect(() => {
    gsap.to(imagesContainerRef.current, {
      translateX: `${-currentImage * 100}%`,
      duration: 0.5
    });
    gsap.to(indicatorRef.current, {
      translateX: `${currentImage * 100}%`,
      duration: 0.5
    });
  }, [currentImage]);

  let leftButtonExtraClasses = 'opacity-100';
  let rightButtonExtraClasses = 'opacity-100';
  if (currentImage === 0) {
    leftButtonExtraClasses = 'opacity-0 !pointer-events-none';
  }
  if (currentImage === images.length - 1) {
    rightButtonExtraClasses = 'opacity-0 !pointer-events-none';
  }

  return (
    <div className="w-full h-full flex items-center justify-center py-10 px-2">
      <div className="relative flex flex-col h-full justify-center" {...swipeHandlers}>
        <BouncyButton
          className={`!absolute self-center -left-[100px] transition-all duration-500 ${leftButtonExtraClasses}`}
          onClick={onMoveImages(false)}
        >
          <CircleWithIcon
            extraClasses="!border-[#D9D9D9]"
            icon={<img className="h-6 invert" src={carretLeft} alt="Puščica v desno" />}
          />
        </BouncyButton>
        <div className="sm:w-[512px] max-w-full overflow-hidden h-full flex items-center">
          <div ref={imagesContainerRef} className="sm:w-[512px] max-w-full flex flex-row">
            {images.map((image) => {
              return (
                <div className="min-w-full flex justify-center bg-[#F0F0F0]" key={image}>
                  <img key={image} src={image} className="object-contain" alt="Slika produkta" />
                </div>
              );
            })}
          </div>
        </div>
        <BouncyButton
          className={`!absolute -right-[100px] transition-all duration-500 ${rightButtonExtraClasses}`}
          onClick={onMoveImages(true)}
        >
          <CircleWithIcon
            extraClasses="!border-[#D9D9D9]"
            icon={<img className="h-6 rotate-180 invert" src={carretLeft} alt="Puščica v desno" />}
          />
        </BouncyButton>
        <div className="relative w-full h-1 bg-[rgba(0,0,0,0.05)] rounded mt-2">
          <div
            ref={indicatorRef}
            className="absolute left-0 h-full rounded bg-black"
            style={{ width: `${100 / images.length}%` }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;

import React from 'react';

interface ActivityCardProps {
  comingSoon?: boolean;
  title: string;
  description: string;
  backgroundImage: string;
  onClick: () => void;
}

const ActivityCard: React.FC<ActivityCardProps> = ({
  title,
  description,
  backgroundImage,
  comingSoon = false,
  onClick
}) => {
  return (
    <div
      className={`activity-card group flex flex-col h-[240px] sm:h-[391px] w-[154px] sm:w-[249px] relative justify-end p-2 sm:p-6 ${
        comingSoon ? 'grayscale opacity-[0.85] cursor-not-allowed' : 'cursor-pointer'
      }`}
      onClick={comingSoon ? undefined : onClick}
    >
      {comingSoon && <span className="text-t2">prihaja kmalu</span>}
      <h2 className="text-t1 sm:text-h3">{title}</h2>
      <p className="hidden sm:group-hover:block text-smp" dangerouslySetInnerHTML={{ __html: description }} />
      <img className="absolute left-0 top-0 -z-[1] h-full w-full" alt="" src={backgroundImage} />
    </div>
  );
};

export default ActivityCard;

import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from '../../../src/assets/lotties/loading_animation.json';
import ExitEnterWrapper from '../ExitEnterWrapper/ExitEnterWrapper';

interface LoadingOverlayProps {
  render: boolean;
  variant: 'lottie' | 'black';
  onAnimationComplete?: () => void;
}

export default function LoadingOverlay(props: LoadingOverlayProps) {
  const { render, variant, onAnimationComplete } = props;

  const background = variant === 'black' ? 'bg-[black]' : 'bg-white';

  return (
    <ExitEnterWrapper render={render} instantRender={variant === 'lottie'} onAnimationComplete={onAnimationComplete}>
      <div
        className={`fixed ease-in-out top-0 w-full h-full flex justify-center items-center pb-[15%] z-40 ${background}`}
      >
        {variant === 'lottie' && <Lottie options={{ animationData: loadingAnimation }} height={200} width={200} />}
      </div>
    </ExitEnterWrapper>
  );
}

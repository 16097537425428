import useLookAroundCamera from '../../../hooks/useLookAroundCamera';
import { useContext, useMemo, useState } from 'react';
import { Group } from 'three';
import { GlobalStateContext } from '../../../store/GlobalStateProvider';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import LANDSCAPE_MODEL from 'assets/models/mountain.glb';
import { useGLTF } from '@react-three/drei';

export default function Landscape() {
  const [ref, setRef] = useState<Group | null>(null);
  const { basecampState } = useContext(GlobalStateContext);
  const location = useLocation();

  const panning = useMemo(() => {
    return !basecampState.isAnimating && location.pathname === ROUTES.BASE_CAMP;
  }, [basecampState.isAnimating, location.pathname]);

  useLookAroundCamera(ref, panning);

  const gltf = useGLTF(LANDSCAPE_MODEL);
  const clonedMesh1 = useMemo(() => gltf.scene.children[0].clone(), [gltf.scene.children]);
  const clonedMesh2 = useMemo(() => gltf.scene.children[0].clone(), [gltf.scene.children]);
  const clonedMesh3 = useMemo(() => gltf.scene.children[0].clone(), [gltf.scene.children]);
  const clonedMesh4 = useMemo(() => gltf.scene.children[0].clone(), [gltf.scene.children]);
  const clonedMesh5 = useMemo(() => gltf.scene.children[0].clone(), [gltf.scene.children]);

  return (
    <group ref={setRef}>
      <group>
        <primitive object={gltf.scene} />
      </group>
      <group position={[532, 0, 0]} rotation={[0, Math.PI, 0]} scale={[1, 1, -1]}>
        <primitive object={clonedMesh1} />
      </group>
      <group position={[0, 0, 510]} rotation={[0, Math.PI * 2, 0]} scale={[1, 1, -1]}>
        <primitive object={clonedMesh2} />
      </group>
      <group position={[532, 0, 510]} rotation={[0, Math.PI, 0]} scale={[1, 1, 1]}>
        <primitive object={clonedMesh3} />
      </group>
      <group position={[532, 0, -571]} rotation={[0, Math.PI, 0]} scale={[1, 1, 1]}>
        <primitive object={clonedMesh4} />
      </group>
      <group position={[0, 0, -571]} rotation={[0, 0, 0]} scale={[1, 1, -1]}>
        <primitive object={clonedMesh5} />
      </group>
    </group>
  );
}

import React, { Suspense, memo } from 'react';
import SkyBox from '../SkyBox/SkyBox';
import Landscape from '../Landscape/Landscape';
import { useThree } from '@react-three/fiber';
import { PerformanceMonitor, PerformanceMonitorApi } from '@react-three/drei';

function MainScene() {
  const setDpr = useThree((s) => s.setDpr);

  function onPerformanceChange({ factor }: PerformanceMonitorApi) {
    setDpr(0.5 + 1.5 * factor);
  }

  return (
    <>
      <PerformanceMonitor onChange={onPerformanceChange} />
      <Suspense>
        <SkyBox />
      </Suspense>
      <Suspense>
        <Landscape />
      </Suspense>
    </>
  );
}

export default memo(MainScene);

import { Canvas } from '@react-three/fiber';
import { getProject } from '@theatre/core';
import { SheetProvider, PerspectiveCamera } from '@theatre/r3f';

import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import igluState from '../../../assets/theatre/iglu_state.json';
import { CAMERAS, ROUTES } from '../../../constants';
import { useLocation } from 'react-router-dom';
import { GlobalStateContext } from '../../../store/GlobalStateProvider';
import usePrevious from '../../../hooks/usePrevious';
const project = getProject('Iglujeva pot v gore', { state: igluState });
const camera1Sheet = project.sheet('Intro1');
const camera2Sheet = project.sheet('Intro2');
const camera3Sheet = project.sheet('Journey');
const sequence1 = camera1Sheet.sequence;
const sequence2 = camera2Sheet.sequence;
const sequence3 = camera3Sheet.sequence;

interface MainCanvasProps {
  children?: React.ReactNode;
}

function MainCanvas(props: MainCanvasProps) {
  const { children } = props;
  const [currentIntro, setCurrentIntro] = useState(CAMERAS.INTRO_1);
  const { pathname } = useLocation();
  const { basecampState, setBasecampState, step } = useContext(GlobalStateContext);
  const prevStep = usePrevious(step);

  const currentCamera = useMemo(() => {
    if ([ROUTES.HIKING_GUIDE, ROUTES.BASE_CAMP].includes(pathname)) {
      return CAMERAS.JOURNEY;
    }
    return currentIntro;
  }, [pathname, currentIntro]);

  // Base camp steps animation
  useEffect(() => {
    const direction = prevStep && prevStep > step ? 'reverse' : 'normal';
    let start = step - 1;
    if (direction === 'reverse') {
      start += 1;
    }
    const end = start + 1;
    const stepLength = 2;
    if (end <= 5) {
      camera3Sheet.sequence
        .play({ range: [start * stepLength, end * stepLength], direction, rate: direction === 'reverse' ? 0.8 : 0.5 })
        .then(() => {
          setBasecampState({ ...basecampState, isAnimating: false });
        });
    } else {
      setTimeout(() => {
        setBasecampState({ ...basecampState, isAnimating: false });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sequence3, step]);

  useEffect(() => {
    const currentSequence = currentCamera === CAMERAS.INTRO_1 ? sequence1 : sequence2;
    currentSequence.play({ rate: 0.25 }).then((finished) => {
      if (finished) {
        if (currentIntro === CAMERAS.INTRO_1) {
          setCurrentIntro(CAMERAS.INTRO_2);
        } else {
          setCurrentIntro(CAMERAS.INTRO_1);
        }
      }
    });
  }, [currentCamera, currentIntro]);

  return (
    <div className="fixed h-full min-h-[100lvh] w-screen top-0 left-0 -z-10">
      <Canvas
        gl={{
          preserveDrawingBuffer: true,
          powerPreference: 'high-performance'
        }}
      >
        <SheetProvider sheet={camera1Sheet}>
          <PerspectiveCamera
            theatreKey="Camera1"
            makeDefault={currentCamera === CAMERAS.INTRO_1}
            position={[5, 5, -5]}
            fov={75}
            attachArray={undefined}
            attachObject={undefined}
            attachFns={undefined}
          />
        </SheetProvider>
        <SheetProvider sheet={camera2Sheet}>
          <PerspectiveCamera
            theatreKey="Camera2"
            makeDefault={currentCamera === CAMERAS.INTRO_2}
            position={[5, 5, -5]}
            fov={75}
            attachArray={undefined}
            attachObject={undefined}
            attachFns={undefined}
          />
        </SheetProvider>
        <SheetProvider sheet={camera3Sheet}>
          <PerspectiveCamera
            theatreKey="Camera3"
            makeDefault={currentCamera === CAMERAS.JOURNEY}
            position={[5, 5, -5]}
            fov={75}
            attachArray={undefined}
            attachObject={undefined}
            attachFns={undefined}
          />
        </SheetProvider>
        {children}
      </Canvas>
    </div>
  );
}

export default memo(MainCanvas);

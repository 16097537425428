import React, { useContext } from 'react';
import markation from '../../assets/images/markation.png';
import Button from '../../components/Button';
import textBackground from '../../assets/images/text_background_1.png';
import buttonLeftImage from '../../assets/images/button_1_left.png';
import buttonRightImage from '../../assets/images/button_1_right.png';
import TextBackground from '../../components/TextBackground';
import ContentWrapper from '../../components/ContentWrapper';
import { ReactComponent as IgluLogo } from '../../assets/images/iglu_logo.svg';
import { GlobalStateContext } from '../../store/GlobalStateProvider';

interface LandingPageProps {
  onContinue: () => void;
}

export default function LandingPage(props: LandingPageProps) {
  const { onContinue } = props;
  const { setSound } = useContext(GlobalStateContext);

  function enterWithSound() {
    setSound(false);
    onContinue();
  }

  function enterWithoutSound() {
    setSound(true);
    onContinue();
  }

  return (
    <>
      <ContentWrapper className="justify-center z-30" navigationNegativeMargin>
        <img src={markation} alt="markacija" className="h-[89px] sm:h-[103px]" />
        <TextBackground backgroundImage={textBackground} className="w-[380px] sm:w-[440px]">
          <h1 className="text-black text-center">alpski svizec</h1>
        </TextBackground>
        <span className="mb-[70px] mt-4 text-t1">vodič, ki ti najde pravo opremo</span>
        <Button
          onClick={enterWithSound}
          variant="big"
          className="relative"
          leftImage={buttonLeftImage}
          rightImage={buttonRightImage}
        >
          gremo
        </Button>
        <Button variant="basic" className="mt-6" onClick={enterWithoutSound}>
          <span className="text-t2 underline">vstopi brez vzoka</span>
        </Button>
        <IgluLogo className="relative sm:absolute sm:bottom-6 w-[200px] scale-150" />
      </ContentWrapper>
      <div className="w-full h-[100%] fixed -bottom-[50%] bg-gradient-radial from-black to-[rgba(0,0,0,0.1)] blur-xl" />
    </>
  );
}

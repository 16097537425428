import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import extension from "@theatre/r3f/dist/extension";
// import studio from "@theatre/studio";

// if (process.env.NODE_ENV === "development") {
// studio.initialize();
// studio.extend(extension);
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// studio.initialize();

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

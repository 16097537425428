import React from 'react';
import Button from '../Button';
import productBackground from '../../assets/images/product_background.png';
import IconLabel from '../IconLabel';
import buttonLeftImage from '../../assets/images/button_1_left.png';
import buttonRightImage from '../../assets/images/button_1_right.png';
import labelBackground from '../../assets/images/label_background_2.png';
import SpecialistReview from '../SpecialistReview';
import { getProductsImages, productHasReview, stripHtmlTags } from '../../utils/helpers';
import { Product } from '../../types/interfaces';

interface ProductDetailsProps {
  product: Product;
  onReadMore: () => void;
  onImageClick: (index: number) => void;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ product, onReadMore, onImageClick }) => {
  if (!product) return null;

  function onImageClickHandler(index: number) {
    return () => onImageClick(index);
  }

  const images = getProductsImages(product);
  const { discount } = product;

  const displayDiscount = !!(discount && discount > 0);
  const displayReview = productHasReview(product);

  return (
    <>
      <div className="relative flex flex-col h-full justify-center">
        <div className="flex flex-col-reverse sm:flex-row flex-1 items-center justify-center sm:justify-between">
          <div className="flex flex-row sm:flex-col w-full sm:w-auto justify-between items-end sm:items-start">
            <div>
              <img src={product.brandImageUrl} alt="" className="max-h-[74px] max-w-[160px] mb-1 sm:mb-4" />
              <h3 className="text-t2 sm:text-h3">{product.title}</h3>
              <p className="text-smp sm:text-p">{product.subTitle}</p>
              <div className="flex flex-row sm:mb-4 mt-1.5">
                <span className="text-h3 sm:text-h2">
                  {(product.price * (1 - product.discount / 100)).toFixed(2)} €
                </span>
                {displayDiscount && (
                  <span className="text-p sm:text-h4 line-through leading-[88px] ml-[12px]">{product.price}€</span>
                )}
              </div>
            </div>
            <a href={product.igluLink} target="_blank" rel="noreferrer" className="basis-[168px]">
              <Button className="relative" leftImage={buttonLeftImage} rightImage={buttonRightImage}>
                pojdi na izdelek
              </Button>
            </a>
          </div>
          <div className="flex flex-row ml-0 mb-4 sm:mb-0 sm:w-[55%] sm:justify-between sm:pr-8">
            {displayDiscount && (
              <IconLabel
                background={labelBackground}
                className="absolute text-white red-label z-10 ml-[-30px] mt-[-30px]"
                backgroundSize={185}
              >
                <div className="text-center">
                  <p className="font-bigNoodle mt-[-30px]">akcija</p>
                  <h2 className="font-bigNoodle leading-[60px]">-{product.discount}%</h2>
                </div>
              </IconLabel>
            )}
            <div className="relative flex justify-center">
              <img className="max-h-[320px] sm:max-h-[628px] z-10 max-w-full self-center" src={images[0]} alt="" />
              <img className="absolute inset-y-0 my-auto" src={productBackground} alt="Product background." />
            </div>
            <div className="flex flex-col gap-[8px] justify-center ml-2 sm:ml-6">
              {images.slice(0, 4).map((image: string, index: number) => {
                return (
                  <div
                    key={image}
                    className="flex flex-col bg-[#F0F0F0] p-[4px] w-[48px] sm:w-[80px] h-[48px] sm:h-[80px] cursor-pointer border-2 border-white"
                    onClick={onImageClickHandler(index)}
                  >
                    <img src={image} alt="" className="max-h-full max-w-full self-center" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {displayReview && <SpecialistReview review={stripHtmlTags(product.description)} onReadMore={onReadMore} />}
      </div>
    </>
  );
};

export default ProductDetails;

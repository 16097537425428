import LandingPage from './pages/LandingPage';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ProductsPage from './pages/ProductsPage';
import BaseCampPage from './pages/BaseCampPage';
import MainCanvas from './rendering/components/MainCanvas/MainCanvas';
import MainScene from './rendering/components/MainScene/MainScene';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import Navigation from './components/Navigation/Navigation';
import GlobalStateContextProvider from './store/GlobalStateProvider';
import { ROUTES } from './constants';
import { useProgress } from '@react-three/drei';
import { useState } from 'react';

function App() {
  const { pathname } = useLocation();
  const [renderBlackOverlay, setRenderBlackOverlay] = useState(false);
  const navigate = useNavigate();

  const progress = useProgress((i) => i.loaded / i.total);
  const isDone = progress === 1;

  function onLandinPageContinue() {
    setRenderBlackOverlay(true);
    setTimeout(() => {
      setRenderBlackOverlay(false);
    }, 750);
  }

  function onBlackOverlayVisible() {
    navigate(ROUTES.BASE_CAMP);
  }

  return (
    <GlobalStateContextProvider>
      <div
        className={`w-full h-full flex flex-col ${
          pathname === ROUTES.PRODUCTS ? 'overflow-hidden' : 'overflow-visible'
        }`}
      >
        <LoadingOverlay
          render={!isDone || renderBlackOverlay}
          variant={!isDone ? 'lottie' : 'black'}
          onAnimationComplete={renderBlackOverlay ? onBlackOverlayVisible : undefined}
        />
        <Navigation />
        <Routes>
          <Route index element={<LandingPage onContinue={onLandinPageContinue} />} />
          <Route path={ROUTES.BASE_CAMP} element={<BaseCampPage />} />
          <Route path={ROUTES.PRODUCTS} element={<ProductsPage />} />
        </Routes>
        <div className="vignette z-[-1]" />
        <MainCanvas>
          <MainScene />
        </MainCanvas>
      </div>
    </GlobalStateContextProvider>
  );
}

export default App;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import SelectionStep from '../../components/SelectionStep';
import { Filter, FilterOption, FilterType, FilterWithSelectedValue } from '../../types/interfaces';
import { getFilters } from '../../api/filters';
import { GlobalStateContext } from '../../store/GlobalStateProvider';
import { ROUTES } from '../../constants';

export default function BaseCampPage() {
  const {
    basecampState: { isAnimating, selectedFilters },
    setBasecampState,
    prevPathname
  } = useContext(GlobalStateContext);
  const [filters, setFilters] = useState<Filter[]>([]);
  const navigate = useNavigate();

  const currentFilter = useMemo(() => {
    let previousFilterWithValue: FilterWithSelectedValue | null = null;
    if (selectedFilters.length) {
      previousFilterWithValue = selectedFilters.at(-1)!;
    }
    const previousId = previousFilterWithValue?.filter.id || null;
    return filters.find(
      (f) =>
        f.parentFilterId === previousId &&
        (f.parentFilterId === null || f.parentFilterOptionId === previousFilterWithValue!.value.id)
    )!;
  }, [selectedFilters, filters]);

  useEffect(() => {
    if (!currentFilter && selectedFilters.length > 0 && prevPathname !== ROUTES.PRODUCTS) {
      const search = new URLSearchParams();
      selectedFilters.forEach((f) => {
        search.append(f.filter.value, f.value.value);
      });
      navigate('/izdelki?' + search.toString());
    }
  }, [navigate, currentFilter, selectedFilters, prevPathname]);

  useEffect(() => {
    getFilters().then((data) => {
      setFilters(data.data.filters);
    });
  }, []);

  function onBack() {
    return selectedFilters.length === 0
      ? undefined
      : () => {
          setBasecampState({
            isAnimating: true,
            selectedFilters: selectedFilters.slice(0, -1)
          });
        };
  }

  function onOptionSelect(filter: Filter, option: FilterOption) {
    setBasecampState({
      isAnimating: true,
      selectedFilters: [...selectedFilters, { filter, value: option }]
    });
  }

  if (!currentFilter || !filters.length) {
    return null;
  }

  if (isAnimating) {
    return null;
  }

  return (
    <SelectionStep
      className={`${currentFilter.type === FilterType.big ? '!max-w-[unset]' : ''}`}
      onContinue={(option) => onOptionSelect(currentFilter, option)}
      {...currentFilter}
      onBack={onBack()}
      index={selectedFilters.length + 1}
      isBaseCamp={!currentFilter.parentFilterId}
      options={currentFilter.filterOptions}
    />
  );
}

import React from 'react';
import specialistIconBlack from '../../assets/images/specialist_info_black.png';
import specialistIconWhite from '../../assets/images/specialist_info_white.png';
import textBackground3 from '../../assets/images/text_background_3.png';
import textBackground4 from '../../assets/images/text_background_4.png';
import TextBackground from '../TextBackground';

interface SpecialistReviewProps {
  review: string;
  iconType?: ['black', 'white'];
  className?: string;
  onReadMore: () => void;
}

const SpecialistReview: React.FC<SpecialistReviewProps> = ({
  review,
  iconType = 'black',
  className = '',
  onReadMore
}) => {
  return (
    <div className={`hidden sm:flex flex-col ${className} relative pl-14 z-10 mb-12`}>
      <img
        className="absolute -left-8 mt-[-12px] z-10"
        src={iconType === 'black' ? specialistIconBlack : specialistIconWhite}
        alt=""
      />
      <TextBackground backgroundImage={textBackground3} className="self-start" imgClassName="min-w-[120%]">
        <span className="text-black text-t1 pl-[40px]">mnenje specialista</span>
      </TextBackground>
      <TextBackground
        backgroundImage={textBackground4}
        imgClassName="-ml-6 min-w-[100%] h-[160%]"
        className="self-start"
      >
        <p className="text-black px-[40px] max-w-[1140px] overflow-elipsis overflow-clip">
          {(review || '').substring(0, 249)}...{` `}
          <span className="font-bold cursor-pointer" onClick={onReadMore}>
            preberi več
          </span>
        </p>
      </TextBackground>
    </div>
  );
};

export default SpecialistReview;

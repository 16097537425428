import axios from 'axios';
import { API_URl, API_TOKEN, HTTP_TIMEOUT } from '../constants';

const service = axios.create({
  timeout: HTTP_TIMEOUT,
  baseURL: API_URl
});

service.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${API_TOKEN}`;
  return config;
});

const api = {
  get<T>(path: string, config?: any) {
    return service.get<T>(path, config);
  }
};

export default api;

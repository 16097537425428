import React from 'react';
import { Product } from '../../types/interfaces';

interface ProductReviewProps {
  product: Product;
}

const ProductReview: React.FC<ProductReviewProps> = ({ product }) => {
  return (
    <div className="relative mt-[70px] max-w-[700px] pb-10">
      <h3 className="mb-8">mnenje specialista</h3>
      <div dangerouslySetInnerHTML={{ __html: product.description }}></div>
    </div>
  );
};

export default ProductReview;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Product } from '../types/interfaces';
import { getFilteredProducts } from '../api/products';

function useProducts() {
  const [products, setProducts] = useState<Product[]>([]);
  const { productId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetch = async () => {
      const filterOptionPairs: string[] = [];
      searchParams.forEach((value, key) => {
        filterOptionPairs.push(`${key}:${value}`);
      });
      const result = await getFilteredProducts({ filterOptionPairs: filterOptionPairs.join(',') });
      setProducts(result.data.products);
    };

    fetch();
  }, [searchParams]);

  return [products.find((p) => p.id.toString() === productId) || {}, products] as [Product, Product[]];
}

export default useProducts;

import React from 'react';
import ExitEnterWrapper from '../../components/ExitEnterWrapper/ExitEnterWrapper';
import iconCross from '../../assets/images/ic-cross.png';
import BouncyButton from '../../components/BouncyButton/BouncyButton';
import CircleWithIcon from '../../components/CircleWithIcon/CircleWithIcon';

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export default function Modal(props: ModalProps) {
  const { children, open, onClose } = props;
  return (
    <ExitEnterWrapper render={open} wrapperClasses="fixed">
      <BouncyButton className="!fixed top-4 right-4 z-20" onClick={onClose}>
        <CircleWithIcon
          extraClasses="!border-[#D9D9D9]"
          icon={<img src={iconCross} alt="Ikona" className="w-[32px] aspect-square" />}
        />
      </BouncyButton>
      <div className="fixed bg-white top-0 left-0 w-full h-full z-10">{children}</div>
    </ExitEnterWrapper>
  );
}

import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import NavigationMenu from '../NavigationMenu/NavigationMenu';
import MuteButton from '../MuteButton/MuteButton';
import { ROUTES } from '../../constants';
import partialLogo from '../../assets/images/partial_logo.png';

export default function Navigation() {
  const { pathname } = useLocation();
  const displayNavigation = pathname !== ROUTES.LANDING && pathname !== ROUTES.HIKING_GUIDE;
  const whiteNavigation = matchPath(`/${ROUTES.PRODUCTS}/*`, pathname);
  const displayLogoOnMobile = pathname === ROUTES.BASE_CAMP;

  return (
    <div className="flex flex-row justify-between px-4 sm:px-8 pt-4 sm:pt-8">
      <div className="flex flex-row gap-8 items-start">
        <Link to={ROUTES.LANDING} className={`${displayLogoOnMobile ? 'block' : 'hidden'} sm:block`}>
          <img src={partialLogo} alt="Iglu logo" className="relative h-[27px] z-10 invert" />
        </Link>
        {displayNavigation && (
          <NavigationMenu
            className={`hidden sm:flex ${whiteNavigation ? 'text-white' : 'text-black'}`}
            tabs={[{ name: 'v bazni tabor', url: ROUTES.BASE_CAMP }]}
          />
        )}
      </div>
      <MuteButton className="top-0 right-0" />
    </div>
  );
}

import { useContext, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import BouncyButton from '../BouncyButton/BouncyButton';
import { GlobalStateContext } from '../../store/GlobalStateProvider';

function Line({ index, paused }: { index: number; paused: boolean }) {
  const scale = (1 - Math.abs(index - 2) / 2) * 2 + 1;
  const ref = useRef<HTMLDivElement>(null);
  const [animation, setAnimation] = useState<gsap.core.Tween>();

  useEffect(() => {
    animation && animation?.kill();
    const gsapAnimation = paused
      ? gsap.to(ref.current, {
          height: '5px',
          duration: '0.1'
        })
      : gsap.to(ref.current, {
          height: `random(5, ${scale * 25 + 10})`,
          duration: '0.3',
          repeat: -1,
          ease: 'none',
          yoyoEase: true,
          repeatRefresh: true
        });
    setAnimation(gsapAnimation);

    return () => {
      animation?.kill();
      setAnimation(undefined);
    };
  }, [paused]); // eslint-disable-line

  return (
    <div
      id={`audio-line-${index}`}
      className={`audio-line border-[white] group-hover:border-primary-orange bg-[white] group-hover:bg-primary-orange border-[1px] w-[3px] h-[5px] rounded-full transition-all`}
      ref={ref}
    />
  );
}

function MuteButton({ className = '' }) {
  const { soundMuted, setSound } = useContext(GlobalStateContext);

  function toggleSound() {
    setSound(!soundMuted);
  }

  return (
    <BouncyButton
      className={`
        circle-button group z-10
      ${className}`}
      onClick={toggleSound}
    >
      <div className="circle-1" />
      <div className="circle-2" />
      <div className="flex w-full h-full rounded-full flex-row justify-between items-center overflow-hidden px-[14px]">
        {Array(5)
          .fill(0)
          .map((_, index) => (
            <Line key={index} paused={soundMuted} index={index} />
          ))}
      </div>
    </BouncyButton>
  );
}

export default MuteButton;

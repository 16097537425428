import React from 'react';
import radioBackground from '../../assets/images/radio_background.png';
import IconLabel from '../IconLabel';

interface RadioOptionProps {
  title: string;
  description?: string;
  backgroundRotation: number;
  onSelect: () => void;
  icon: string;
}

const RadioOption: React.FC<RadioOptionProps> = ({ title, description, backgroundRotation, onSelect, icon }) => {
  return (
    <div
      className={`radio-option relative flex flex-[110] flex-row items-start gap-[35px] hover:text-primary-orange cursor-pointer`}
      onClick={onSelect}
    >
      <IconLabel
        className="relative flex-shrink-0 -translate-y-[33%]"
        background={radioBackground}
        backgroundRotation={backgroundRotation}
        textColor="black"
        fontSize={50}
        backgroundSize={70}
      >
        <img className="w-[42px] aspect-square" src={icon} alt="" />
      </IconLabel>
      <div className="flex flex-col">
        <h2 className="text-t1">{title}</h2>
        <p className="mt-3" dangerouslySetInnerHTML={{ __html: description || '' }} />
      </div>
      <div className="absolute sm:scale-125 h-full w-full rounded-full blur-2xl bg-[rgba(0,0,0,0.6)] -z-[1]" />
    </div>
  );
};

export default RadioOption;

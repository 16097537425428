import React from 'react';
import { useTexture } from '@react-three/drei';
import * as THREE from 'three';
import sky from '../../../assets/images/day_2.jpg';

export default function SkyBox() {
  const skyTexture = useTexture(sky);

  return (
    <mesh>
      <sphereGeometry args={[1400, 150, 60]} />
      <meshBasicMaterial map={skyTexture} side={THREE.BackSide} />
    </mesh>
  );
}

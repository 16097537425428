export interface Filter {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  parentFilterId: number;
  parentFilterOptionId: number;
  title: string;
  description: string;
  value: string;
  type: FilterType;
  audioUrl: string;
  iconUrl: string;
  filterOptions: FilterOption[];
}

export interface FilterWithSelectedValue {
  filter: Filter;
  value: FilterOption;
}

export interface FilterOption {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  filterId: number;
  title: string;
  description: string;
  value: string;
  iconUrl: string;
  imageUrl: string;
  isDisabled: boolean;
}

export enum FilterType {
  'big' = 'big',
  'small' = 'small'
}

export interface Product {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  subTitle: string;
  description: string;
  technicalDescription: string;
  igluLink: string;
  price: number; // cents
  discount: number; // percent
  audioUrl: string;
  brandImageUrl: string;
  heroImageUrl?: string;
  image1Url?: string;
  image2Url?: string;
  image3Url?: string;
  image4Url?: string;
  image5Url?: string;
  image6Url?: string;
  image7Url?: string;
}
